// Define component
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { mmToPdfPoints } from "../../../utils/functions"
import { Divider, PointDivider } from "../../PhysicalTickets"
import { constants } from "../../../constants"
import React from "react"

const QR_WIDTH = mmToPdfPoints(20)

// Define styles
const styles = StyleSheet.create({
  centerAll: {
    justifyContent: "center",
    alignItems: "center",
  },
  centerVertically: {
    flex: 1,
    justifyContent: "center",
  },
  oneLineContainer: {
    width: 236, // set the width to your desired value
    overflow: "hidden",
  },
  oneLineText: {
    whiteSpace: "nowrap",
  },
})

const ButtonWristbandTicket = ({
  ticket,
  event,
  position,
  index,
  seat,
  qrImageUrl,
  dimensions,
  printData,
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        height: mmToPdfPoints(dimensions.height),
      }}
    >
      <View
        style={{
          flexDirection: "row",
          width: mmToPdfPoints(dimensions.width_1),
        }}
      ></View>
      <View
        style={{
          width: mmToPdfPoints(dimensions.width_2),
          flexDirection: "row",
          // paddingLeft: mmToPdfPoints(10),
        }}
      >
        <View
          style={[
            {
              width: mmToPdfPoints(dimensions.width_2_1),
              // justifyContent: "center",
            },
            styles.centerAll,
          ]}
        >
          {seat && (
            <View style={{ marginBottom: 0 }}>
              <Text style={{ fontSize: 8 }}>{`SEAT ${seat}`}</Text>
            </View>
          )}
          <Image
            src={qrImageUrl}
            style={{
              width: QR_WIDTH,
              height: QR_WIDTH * 1,
              padding: 0,
              marginBottom: 1,
            }}
          />

          <Text
            style={{
              fontSize: 10,
              fontWeight: "bold",
              textAlign: "center",
              marginTop: -3,
            }}
          >
            {ticket.extra_id.replace("VTX-PHY-", "")} #{index + 1}
          </Text>
        </View>

        <View
          style={[
            {
              width: mmToPdfPoints(dimensions.width_2_2),
              marginLeft: mmToPdfPoints(1),
            },
            styles.centerVertically,
          ]}
        >
          {/*<PointDivider height={0.5} />*/}
          <Text
            style={{
              fontSize: 15,
              fontWeight: "bold",
              textOverflow: "ellipsis",
            }}
          >
            {`${event.name}`.slice(0, 21)}
            {event.name.length > 21 ? "..." : ""}
          </Text>
          <PointDivider height={2} />
          <Text style={{ fontSize: 8 }}>{event.start_datetime}</Text>
          <PointDivider height={1} />
          <View style={styles.oneLineContainer}>
            <Text style={[{ fontSize: 8 }, styles.oneLineText]}>
              {`${event.full_address}`.slice(0, 44)}
              {event.full_address.length > 44 ? "..." : ""}
            </Text>
          </View>
          <PointDivider height={2} />
          <Text style={{ fontSize: 12 }}>
            {ticket.name}
            {ticket.is_public ? ` | ${ticket.price}` : ""}
            {ticket.multiplier === 1
              ? ""
              : ` | ${position} of ${ticket.multiplier}`}
          </Text>
          <PointDivider height={1} />
        </View>
      </View>
      <View style={{ width: mmToPdfPoints(dimensions.width_3) }}>
        <Divider />
      </View>
    </View>
  )
}

export default ButtonWristbandTicket

import React, { useEffect, useState } from "react"
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer"
import { ConditionalView, LoadingView } from "../utils/components"
import { apiRequest } from "../requests"
import { useParams } from "react-router-dom"
import { Divider } from "./PhysicalTickets"
import QRCode from "qrcode"
import { constants } from "../constants"
import { SA_SEATS } from "../data/southern_arena_seating"
import WristbandTicket, {
  WRISTBAND_DIMENSIONS,
} from "./components/tickets/wristbandticket"
import WristbandForm from "./forms/tickets"
import { Card, Col } from "react-bootstrap"
import NavBar from "./NavBar"
import { arrangeTickets, mmToPdfPoints, mmToPixels } from "../utils/functions"

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    // padding: 3,
  },
  centerAll: {
    justifyContent: "center",
    alignItems: "center",
  },
  oneLineContainer: {
    width: 236, // set the width to your desired value
    overflow: "hidden",
  },
  oneLineText: {
    whiteSpace: "nowrap",
  },
})

const WristBandTicket = ({ tickets, event, printData }) => {
  const seats = SA_SEATS[tickets[0].name]

  let rearrangedTickets = arrangeTickets(
    tickets,
    printData.perRoll,
    printData.simultaneously
  )

  const dimensions = WRISTBAND_DIMENSIONS[printData.dimensions]

  return (
    <Document>
      {rearrangedTickets.map((ticketsList, i) => {
        return (
          <Page
            key={i}
            size={{
              width: mmToPdfPoints(dimensions.total_width),
              height: mmToPdfPoints(dimensions.height) * ticketsList.length,
            }}
            style={styles.page}
          >
            {ticketsList.map((ticket, i) => {
              const index = ticket.index
              const diff = index % ticket.multiplier
              const position = diff === 0 ? ticket.multiplier : diff

              let seat = null
              if (seats) {
                seat = seats[i]
                if (!seat) {
                  return null
                }
              }

              return (
                <WristbandTicket
                  key={i}
                  ticket={ticket}
                  event={event}
                  index={ticket.index - 1}
                  seat={seat}
                  position={position}
                  qrImageUrl={IMAGE_URLS[ticket.id]}
                  dimensions={dimensions}
                  printData={printData}
                />
              )
            })}
          </Page>
        )
      })}
    </Document>
  )
}

const IMAGE_URLS = {}

export default function WristbandsPdf() {
  useEffect(() => {
    getInfo()
  }, [])

  const [printData, setPrintData] = useState({
    simultaneously: 5,
    perRoll: 200,
    dimensions: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [qrImage, setQrImage] = useState(null)
  const [totalArray, setTotalArray] = useState([])
  const [event, setEvent] = useState({})
  const [tickets, setTickets] = useState(null)
  const [wristbandInfo, setWristbandInfo] = useState(null)
  const { orderItemId } = useParams()

  const getInfo = async () => {
    setIsLoading(true)

    const { success, response } = await apiRequest.get(
      `api/physical/tickets/${orderItemId}`
    )
    if (success) {
      if (response.tickets) {
        setEvent(response.event)
        response.tickets.forEach(async (ticket) => {
          IMAGE_URLS[ticket.id] = await QRCode.toDataURL(ticket.reference_id)
        })
        setTickets(response.tickets)
      } else {
        setWristbandInfo(response.wristband_info)
        setTotalArray(new Array(response.total).fill("0"))
        const qr = await QRCode.toDataURL(response.wristband_info.event.code)
        setQrImage(qr)
      }
    }
    setIsLoading(false)
  }

  if ((!wristbandInfo || !qrImage) && !tickets) {
    return (
      <LoadingView
        isLoading={isLoading}
        view={<div />}
      />
    )
  }

  if (!printData.dimensions) {
    return (
      <>
        <NavBar />
        <div
          className="d-flex align-items-center justify-content-center bg-primary vh-100"
          // style={{ minHeight: "100vh" }}
        >
          <Col md={4}>
            <Card>
              <Card.Header className={"py-4"}>
                <Card.Title>Set Print Options</Card.Title>
              </Card.Header>
              <Card.Body>
                <WristbandForm
                  initialState={{ ...printData }}
                  onSubmit={(data) => {
                    setPrintData(data)
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </div>
      </>
    )
  }
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"text-center"}>
          <PDFViewer
            width={window.innerWidth}
            height={window.innerHeight}
          >
            <ConditionalView
              condition={tickets}
              trueView={
                <WristBandTicket
                  tickets={tickets}
                  event={event}
                  printData={printData}
                />
              }
              falseView={
                <></>
                // <WristBand
                //   info={wristbandInfo}
                //   totalArray={totalArray}
                //   qrUrl={qrImage}
                // />
              }
            />
          </PDFViewer>
        </div>
      }
    />
  )
}
